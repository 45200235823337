@import 'variables', 'text';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-tooltip {
  @extend %text-very-small;

  background-color: white;
  color: $wizbii-black !important;
  border-radius: 0.625rem !important;
  border: none;
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.15);
  padding: 0.875rem !important;
  white-space: pre-line;
}
