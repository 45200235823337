@import 'breakpoints', 'text';

.confirmation-saved {
  @extend %text-small-semibold;

  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  &__icon {
    color: $valid-green;
  }
}
