@use '@angular/cdk' as cdk;
@use 'variables' as var;
@use '~@wizbii/styles/modules/wzb-variables' as wzbVar;
@use '~@wizbii/styles/modules/override/material/mat-form-field' as wzbFormField;
@use '~@wizbii/styles/modules/override/material/mat-select' as wzbSelect;
@use '~@wizbii/styles/modules/override/material/mat-autocomplete' as wzbAutocomplete;
@use '~@wizbii/styles/modules/wzb-theming/wzb-position-picker' as posPicker;
@use '~@wizbii/styles/modules/wzb-theming/wzb-button' as btn;
@use '~@wizbii/styles/modules/wzb-theming/wzb-caps-indicator' as capsIndicator;
@use '~@wizbii/styles/modules/wzb-theming/wzb-button-password' as buttonPassword;
@use '~@wizbii/styles/modules/wzb-theming/wzb-show-password-button' as showPasswordButton;
@use '~@wizbii/styles/modules/wzb-theming/wzb-notifications' as notifications;
@use '~@wizbii/styles/modules/wzb-theming/wzb-toggle' as toggle;
@use '~@wizbii/styles/modules/wzb-theming/wzb-checkbox' as checkbox;
@use '~@wizbii/styles/modules/wzb-theming/wzb-date' as date;
@use '~@wizbii/styles/modules/wzb-theming/wzb-contact-modal' as contactModal;
@use '~@wizbii/styles/modules/wzb-theming/wzb-faq' as faq;
@use '~@wizbii/styles/modules/wzb-theming/wzb-mobile-menu' as mobileMenu;

// Angular material
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Add normalize
@import '~normalize.css';

@import 'utils', 'themes', 'override/material', 'override/widgets/tinymce', 'override/trustbox/trustbox', 'variables',
  'button', 'breakpoints', 'heading', 'text', 'loader', 'gdpr', 'safe-area', 'white-box', 'form';

:root {
  --blue: 0 209 255;
  --light-blue: 246, 250, 255;
  --purple: 134 131 255;
  --green: 61 249 198;
  --red: 255 92 97;
  --yellow: 251 237 33;
  --dark-blue: 0 0 40;
  --success-green: 58 234 152;
  --electric-blue: 87 80 251;
  --wizbii-blue: 69 207 255;
  --wizbii-dark-blue: 0 0 40;
  --valid-green: 0 238 152;

  --primary: rgb(var(--blue));
  --secondary: rgb(var(--green));
}

html {
  overflow: visible !important;
  scroll-behavior: smooth;
  font-family: $font-family-sans-serif;
  line-height: 1.625;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding-right: 0 !important;
  overflow-x: hidden !important; // setting `overflow-y` has the unwanted effect of setting `overflow-x` to `auto`...
  overflow-y: scroll !important; // prevent jumps when switching between long and short pages
  background-color: $theme-bg;
  color: $wizbii-black;
}

textarea {
  font-family: inherit;
  resize: vertical;
}

pre {
  overflow: auto;
}

label,
output {
  display: inline-block;
}

img,
svg {
  vertical-align: middle;
}

svg {
  display: block;
  border: 0;
  fill: currentColor;

  &:not(:root) {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration-skip-ink: auto;
  text-decoration: none;

  &:not([href]) {
    text-decoration: none !important;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

input {
  @include cdk.text-field-autofill-color(transparentize($theme-primary, 0.8), $wizbii-black);

  // stylelint-disable-next-line selector-no-qualifying-type
  &[type='number'] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

button,
summary {
  cursor: pointer;
}

strong {
  font-weight: 600;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[hidden] {
  display: none !important;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

@media (prefers-reduced-motion) {
  * {
    transition: none !important;
    animation: none !important;
  }
}

.lock {
  overflow: hidden;
}

.hover-on {
  a:hover {
    text-decoration: underline;
  }
}

.highlighted {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-image: linear-gradient(
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0) 12%,
    $wizbii-blue 0,
    $wizbii-blue 70%,
    hsla(0, 0%, 100%, 0) 0
  );
}

// Material override
@include wzbFormField.wzbMatFormField(var.$theme-primary, var.$theme-secondary, var.$warn-orange);
@include wzbSelect.wzbMatSelect(var.$theme-primary);
@include wzbAutocomplete.wzbMatAutocomplete(var.$theme-primary);

// Wizbii components
@include posPicker.wzbPositionPicker(var.$theme-primary, var.$theme-secondary);
@include btn.wzbButton(var.$theme-primary, var.$theme-secondary);
@include btn.wzbSuggestionButton(var.$grey-2, var.$theme-primary, var.$theme-secondary, var.$invalid-red);
@include capsIndicator.wzbCapsIndicator(var.$theme-primary, var.$theme-secondary);
@include buttonPassword.wzbButtonPassword(var.$theme-primary, var.$theme-secondary);
@include showPasswordButton.wzbShowPasswordButton(var.$theme-primary, var.$theme-secondary);
@include notifications.wzbNotifications(var.$theme-primary, var.$theme-secondary);
@include toggle.wzbToggle(var.$theme-primary, var.$theme-secondary, var.$warn-orange);
@include checkbox.wzbCheckbox(var.$theme-primary, var.$theme-secondary, var.$warn-orange);
@include date.wzbDate(var.$theme-primary, var.$theme-secondary, var.$warn-orange);
@include contactModal.wzbContactModal(var.$theme-primary, var.$theme-secondary, var.$invalid-red);
@include faq.wzbFaq(var.$theme-primary, var.$theme-secondary, var.$invalid-red);
@include mobileMenu.wzbMobileMenu(var.$theme-primary, var.$theme-secondary);
