// stylelint-disable selector-max-specificity
@use '~@wizbii/styles/modules/wzb-variables' as variables;

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
  background-color: variables.$wizbii-blue !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: variables.$wizbii-blue !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: variables.$wizbii-blue !important;
}

.mat-radio-group.ng-invalid.ng-touched .mat-radio-outer-circle {
  background-color: #ffe7e7 !important;
  border-color: transparent !important;
}

.mat-radio-group.ng-invalid.ng-touched .mat-radio-label-content {
  color: variables.$invalid-red !important;
}
