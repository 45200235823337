$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue',
  'Arial', 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Wizbii brand colors
$wizbii-purple: #8782ff;
$wizbii-purple-transparent-32: rgba(135, 130, 255, 0.32);
$wizbii-purple-dark: #0a2456;
$wizbii-blue: #45cfff;
$wizbii-yellow: #f9ed20;
$wizbii-red: #ff5c60;
$wizbii-green: #3df9c6;
$wizbii-pink: #e8a3f4;
$wizbii-black: #000028;
$wizbii-placeholder-grey: #e3e3e3;

// Alert colors
$valid-green: #3aea98;
$warn-orange: #ffbf16;
$invalid-red: #ff5c61;

// Theme
$theme-bg: #f5f8ff;
$theme-primary: $wizbii-blue;
$theme-secondary: $wizbii-black;

// Third-party brand colors
$google-red: #f45c4c;
$facebook-blue: #4872ba;

// Others colors
$grey-2: #f2f2f4;
$grey-4: #d1e1f5;
$grey-6: #b3b3bf;

// Safari hack
$transparent-white: rgba(255, 255, 255, 0);

// Z-index globaux
$zi-modal: 100; // all modals
$zi-toaster: 101; // all toasters
