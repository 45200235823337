@mixin safe-area($top: 0.75rem, $bottom: 0.75rem, $right: 0.75rem, $left: 0.75rem) {
  @supports (padding-bottom: constant(safe-area-inset-bottom)) and (padding-top: constant(safe-area-inset-top)) and
    (padding-right: constant(safe-area-inset-right)) and (padding-left: constant(safe-area-inset-left)) {
    $safe-area-inset-left: constant(safe-area-inset-left, #{$left});
    $safe-area-inset-right: constant(safe-area-inset-right, #{$right});
    $safe-area-inset-top: constant(safe-area-inset-top, #{$top});
    $safe-area-inset-bottom: constant(safe-area-inset-bottom, #{$bottom});

    @content ($safe-area-inset-left, $safe-area-inset-right, $safe-area-inset-top, $safe-area-inset-bottom);
  }

  @supports (padding-bottom: env(safe-area-inset-bottom)) and (padding-top: env(safe-area-inset-top)) and
    (padding-right: env(safe-area-inset-right)) and (padding-left: env(safe-area-inset-left)) {
    $safe-area-inset-left: env(safe-area-inset-left, #{$left});
    $safe-area-inset-right: env(safe-area-inset-right, #{$right});
    $safe-area-inset-top: env(safe-area-inset-top, #{$top});
    $safe-area-inset-bottom: env(safe-area-inset-bottom, #{$bottom});

    @content ($safe-area-inset-left, $safe-area-inset-right, $safe-area-inset-top, $safe-area-inset-bottom);
  }
}
