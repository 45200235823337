@import 'variables', 'text', 'breakpoints';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity

.mat-tab-nav-bar:not([initial]) {
  padding: 0 1.25rem;
  overflow: scroll !important;
  border-bottom: none;
  -ms-overflow-style: none;

  .mat-tab-link-container {
    overflow-x: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .mat-tab-link {
    @extend %text-medium;

    height: 1.875rem;
    text-align: left;
    padding: 0 2.75rem 0 0.625rem;
    justify-content: flex-start;
    color: $grey-6;
    opacity: 1;
    transition: color 0.15s ease-out;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: $theme-primary;
      border-radius: 0.25rem;
      height: 100%;
      width: 0.125rem;
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }

    &.mat-tab-label-active {
      @extend %text-large-semibold;

      color: $wizbii-black;

      &::before {
        opacity: 1;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &:not(.mat-tab-label-active):hover {
      color: $wizbii-black;
    }
  }

  .mat-ink-bar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .mat-tab-links {
    overflow: initial !important;
  }

  @include notHandset {
    padding: 0;
  }
}

.mat-tab-group:not([initial]) {
  padding: 0 1.25rem;

  .mat-tab-header {
    overflow: scroll !important;
    border-bottom: none;
  }

  .mat-tab-link-container {
    overflow-x: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .mat-tab-label {
    @extend %text-medium;

    height: 1.875rem;
    min-width: fit-content;
    text-align: left;
    padding: 0 2.75rem 0 0.625rem;
    justify-content: flex-start;
    color: $grey-6;
    opacity: 1;
    transition: color 0.15s ease-out;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: $theme-primary;
      border-radius: 0.25rem;
      height: 100%;
      width: 0.125rem;
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }

    &.mat-tab-label-active {
      @extend %text-large-semibold;

      color: $wizbii-black;

      &::before {
        opacity: 1;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &:not(.mat-tab-label-active):hover {
      color: $wizbii-black;
    }
  }

  .mat-ink-bar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .mat-tab-links {
    overflow: initial !important;
  }

  @include notHandset {
    padding: 0;
  }
}
.mat-tab-nav-bar[initial] {
  background-color: white;
  border-color: $grey-2;
  box-shadow: 0 0.125rem 1.25rem rgb(0 0 0 / 5%);

  .mat-tab-link {
    @extend %text-very-small;

    color: $wizbii-black;
    opacity: 1;

    &.mat-tab-label-active {
      @extend %text-very-small-semibold;

      color: $theme-primary;
    }
  }
}

.mat-tab-group[initial] {
  background-color: white;
  box-shadow: 0 0.125rem 1.25rem rgb(0 0 0 / 5%);

  .mat-tab-header {
    border-color: $grey-2;
  }

  .mat-tab-label {
    @extend %text-very-small;

    color: $wizbii-black;
    opacity: 1;

    &.mat-tab-label-active {
      @extend %text-very-small-semibold;

      color: $theme-primary;
    }
  }
}

.mat-tab-group[arrow-nav='false'] {
  .mat-tab-label-container {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  }
}

.mat-tab-nav-bar[arrow-nav='false'] {
  .mat-tab-link-container {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  }
}

.mat-tab-nav-bar,
.mat-tab-group {
  &:not([arrow-nav='true']) {
    .mat-tab-header-pagination {
      display: none !important;
    }
  }
}
