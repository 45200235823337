// stylelint-disable selector-no-qualifying-type, selector-type-no-unknown

wzb-abstract-button-list.gender {
  .abstract-list {
    display: flex;
    flex: 1;

    &__btn {
      flex: 1;
    }
  }

  .wzb-suggestion-btn {
    min-width: unset;
  }
}
