@import 'text', 'variables', 'breakpoints';

.top-nav {
  position: sticky !important;
  display: block;
  left: 0;
  right: 0;
  top: 3.3125rem;
  z-index: 49;

  &--not-payed {
    top: 7.25rem;
  }

  @include notHandset {
    position: relative !important;
    left: initial;
    right: initial;
    top: initial;
    z-index: initial;

    &--not-payed {
      top: initial;
    }
  }
}

.tab-mobile {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 1.25rem 0.625rem 1.875rem;
  margin: 0 -1.25rem;

  > * {
    @extend %text-normal-semibold;

    vertical-align: middle;
    overflow: visible;
    color: white;
    border: 1px solid white;
    border-radius: 21px;
    padding: 0.3125rem 0.625rem;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
    transition: color 0.15s ease-out, border-color 0.15s ease-out, background-color 0.15s ease-out;

    &:first-child {
      margin-left: 0.625rem;
    }

    &:last-child {
      margin-right: 0.625rem;
    }

    &[active='true'],
    &.tab--active {
      background-color: $theme-primary;
      color: $wizbii-black;
      border-color: $theme-primary;
    }

    &.tab--lock {
      border-color: $invalid-red;
      color: $invalid-red;
    }
  }

  &__icon {
    margin-left: 0.25rem;
  }
}
