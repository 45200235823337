// Angular material
@use 'node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

@import './styles/tab-mobile';
@import './styles/override/material';
@import './styles/override/angular-ui';
@import 'breakpoints', 'safe-area', 'variables';

html {
  height: 100vh;
  scroll-behavior: auto;

  @include notHandset {
    overflow: hidden !important; /* override date picker styles to fix sticky positioning */
  }
}

body {
  min-height: 100vh;

  :host-context(.fix-ios-bug) & {
    & > * {
      transform: translateZ(0) !important;
    }
  }

  @include notHandset {
    overflow: hidden !important; /* override date picker styles to fix sticky positioning */
  }

  @include handset {
    // Disable selection, highlighting, and callouts for PWA on mobile
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding-bottom: 10vw;

    @include safe-area() using
      ($safe-area-inset-left, $safe-area-inset-right, $safe-area-inset-top, $safe-area-inset-bottom) {
      padding-bottom: calc(10vw + #{$safe-area-inset-bottom});
      padding-left: $safe-area-inset-left;
      padding-right: $safe-area-inset-right !important;
    }
  }
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
