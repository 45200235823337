.tox-tinymce {
  border-radius: 0.625rem !important;
  margin-bottom: 1.25rem;
  border-color: rgba(0, 0, 0, 0.12) !important;

  .tox-editor-header {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }

  .tox-toolbar {
    border-color: rgba(0, 0, 0, 0.12) !important;

    &__group {
      border-color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  .tox-anchorbar {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }

  .tox-sidebar-wrap {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }
}
