@import 'variables';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, selector-max-type
mat-form-field.mat-form-field-appearance-outline {
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-width: 0.062rem !important;
  }

  .mat-form-field-outline-start {
    min-width: 0.625rem;
    border-radius: 0.625rem 0 0 0.625rem;
  }

  .mat-form-field-outline-end {
    min-width: 0.625rem;
    border-radius: 0 0.625rem 0.625rem 0;
  }

  .mat-form-field-outline {
    background: $theme-bg;
    border-radius: 0.625rem;
  }

  &.bg-white {
    .mat-form-field-outline {
      background: white;
    }
  }

  &[transparent] {
    .mat-form-field-outline {
      background: transparent;
    }
  }

  .mat-form-field-suffix {
    top: -0.25rem;
  }

  .mat-form-field-infix {
    padding: 0.85em 0 1.15em 0;
  }

  .mat-form-field-label-wrapper {
    top: -1em;
  }

  .mat-form-field-flex {
    padding-left: 1rem;
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 1.25rem;
  }

  .mat-form-field-subscript-wrapper {
    font-weight: 500;
    margin-top: 0.25rem;
    padding: 0;
    text-align: right;

    mat-error {
      font-size: 0.625rem;
    }
  }

  &:not(.mat-form-field-invalid):not(.mat-focused).mat-form-field-has-label.mat-form-field-should-float {
    .mat-form-field-label {
      color: $theme-primary;
    }
  }

  &.mat-form-field-invalid {
    mat-datepicker-toggle {
      color: $invalid-red;
    }
  }

  mat-select {
    .mat-select-arrow {
      width: 0.5rem;
      height: 0.5rem;
      border-left: none;
      border-right: 0.1rem solid $wizbii-black;
      border-top: 0.1rem solid $wizbii-black;
      margin: 0 0.25rem;
      transform: rotate(135deg);
    }

    &[aria-owns] {
      .mat-select-arrow {
        transform: rotate(-45deg);
      }

      .mat-select-arrow-wrapper {
        transform: none;
      }
    }
  }
}
