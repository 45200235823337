@import 'variables', 'breakpoints';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-drawer {
  border: none;
  background-color: $theme-secondary;
  color: white;
  padding: 1.875rem 0.625rem 0;

  .mat-list-icon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1rem !important;
  }

  .mat-list-item {
    color: white;
    border-radius: 1.25rem;
    transition: box-shadow 0.15s ease-out, background-color 0.15s ease-out;
    color-adjust: exact;

    &:focus {
      box-shadow: inset 0 0 0 0.125rem transparentize(white, 0.6);
      background-color: transparentize(white, 0.8);
    }
  }

  .mat-drawer-inner-container {
    overflow-x: visible;
  }

  &[platform='tablet'] {
    padding: 2.8125rem 0.9375rem 0;

    .mat-list-item-content {
      justify-content: center;
    }
  }
}

.mat-drawer-content {
  position: fixed;
  overflow-x: hidden !important;

  @include handset {
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

.mat-drawer-container {
  position: fixed;

  @include handset {
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

.fix-ios-bug {
  .mat-drawer-content,
  .mat-drawer-container {
    & > * {
      transform: translateZ(0) !important;
    }
  }
}
