@import 'variables';

.mat-slider {
  .mat-slider-track-fill {
    height: 0.25rem !important;
    background-color: $grey-4 !important;
    border-radius: 0.75rem;
  }

  .mat-slider-track-background {
    background-color: $grey-4 !important;
  }

  .mat-slider-thumb {
    height: 1.97rem;
    width: 1.97rem;
    background-color: $theme-primary !important;
    border: 0.25rem solid white !important;
    box-shadow: 0 0.25rem 0.875rem rgba(0, 0, 40, 0.2);
    bottom: -1rem;
  }
}

.mat-slider-horizontal {
  .mat-slider-ticks-container {
    height: 0.25rem !important;
  }

  .mat-slider-wrapper {
    height: 0.25rem !important;
  }

  .mat-slider-ticks {
    height: 0.25rem !important;
  }

  .mat-slider-track-wrapper {
    height: 0.25rem !important;
  }
}
