@mixin loader($size, $color, $speed: 0.7s) {
  box-sizing: border-box;
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 0.25rem solid silver;
  animation: loader-anim $speed linear infinite;
  border-color: $color;
  border-bottom-color: transparent;
}

@keyframes loader-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
